import { uncapitalize } from '@lasso/shared/utils'

export const mapFormError = (mapper: Record<string, string> | ((key: string) => string | undefined), key: string) => {
  const uncapitalizedKey = uncapitalize(key)

  if (typeof mapper === 'function') {
    return mapper(key) ?? mapper(uncapitalizedKey) ?? uncapitalizedKey
  }
  else {
    return mapper[key] ?? mapper[uncapitalizedKey] ?? uncapitalizedKey
  }
}
