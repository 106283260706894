import { objEntries, objFromEntries, truthy } from '@lasso/shared/utils'

/**
 * Binds all api methods to the instance, so that they can be destructured.
 * Returns a new object with bound methods and a mapping between bound methods and original methods.
 */
export const bindApiMethods = <T extends {}>(api: T): [
  boundApi: Omit<T, 'request'>,
  methodsMapping: Array<[boundMethod: Function, originalMethod: Function]>,
] => {
  const methodsMapping: Array<[boundMethod: Function, originalMethod: Function]> = []
  const boundApi = objFromEntries(
    objEntries(api).map(([key, api]) => {
      if (key === 'request') {
        return null
      }

      const methodNames = Object.getOwnPropertyNames(Object.getPrototypeOf(api)) as Array<keyof typeof api>

      const boundApi = objFromEntries(
        methodNames.map((methodName) => {
          if (methodName === 'constructor') {
            return null
          }

          const method = api[methodName]

          if (typeof method !== 'function') {
            return null
          }

          const boundMethod = method.bind(api)
          methodsMapping.push([boundMethod, method])

          return [methodName, boundMethod] as const
        }).filter(truthy),
      )

      return [key, boundApi] as const
    }).filter(truthy),
  ) as T

  return [boundApi, methodsMapping]
}
