import { objValues, truthy } from '@lasso/shared/utils'
import { FormattedError } from '@lasso/shared/types'
import { ToastErrorFormatter } from '@lasso/shared/hooks'

import { object, string } from 'yup'

import { ApiError } from './ApiError'
import { ApiErrorWellFormed } from './ApiErrorWellFormed'

const exceptionErrorSchema = object({
  exceptionMessage: string().required(),
  exceptionDetails: string(),
})

export const errorStateErrorFormatter = (error: unknown): FormattedError => {
  let title = error
  let message: string | undefined

  if (error && typeof error === 'object') {
    if ('message' in error) {
      title = error.message
    }
  }

  if (error instanceof ApiError) {
    const errors: string[] = [
      error.detail,
    ]

    if (error.isDataMatching(exceptionErrorSchema)) {
      errors.push(error.data.exceptionMessage)

      if (error.data.exceptionDetails) {
        errors.push(error.data.exceptionDetails)
      }
    }

    if (error instanceof ApiErrorWellFormed) {
      errors.push(
        ...objValues(error.data.errors ?? {}).flat(),
      )
    }

    message = errors.filter(truthy).join(', ')
  }

  return { title: String(title), message: message ?? '' }
}

export const toastErrorFormatter: ToastErrorFormatter = (title: string, error: unknown) => {
  if (typeof error === 'string') {
    if (title) {
      return { title, message: error }
    }
    else {
      return { title: error, message: '' }
    }
  }
  else if (error instanceof ApiError) {
    if (error.isDataMatching(exceptionErrorSchema)) {
      return { title: title || error.message, message: error.data.exceptionMessage }
    }

    if (title) {
      return { title, message: error.detail || error.message }
    }
    else {
      return { title: error.message, message: error.detail }
    }
  }
  else if (error instanceof Error) {
    if (title) {
      return { title, message: error.message }
    }
    else {
      return { title: error.message, message: '' }
    }
  }
  else {
    return { title: title || 'Unknown error', message: '' }
  }
}
