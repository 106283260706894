import { AxiosError } from 'axios'

import { createError } from './createError'

/**
 * Response error interceptor.
 * Converts AxiosError into ApiError or ApiErrorWellFormed.
 *
 * Note: this interceptor should be applied to all axios instances
 * because shared code may rely on the error type it produces.
 */
export const formatError = (error: AxiosError) => {
  return Promise.reject(createError(error))
}
