import { AxiosError } from 'axios'
import { validate } from '@lasso/shared/validation'

import { unknownErrorSchema, wellFormedErrorSchema } from './validation'
import { ApiErrorParsed } from './types'
import { ApiError } from './ApiError'
import { ApiErrorWellFormed } from './ApiErrorWellFormed'

export const parseError = (error: AxiosError): ApiErrorParsed => {
  if (error.response?.data) {
    const wellFormedValidation = validate(wellFormedErrorSchema, error.response.data)

    if (wellFormedValidation.success) {
      return {
        isWellFormed: true,
        data: wellFormedValidation.data,
        message: (wellFormedValidation.data.title ?? wellFormedValidation.data.detail) || error.message,
        detail: wellFormedValidation.data.detail,
        status: wellFormedValidation.data.status ?? error.response.status,
      }
    }

    const unknownValidation = validate(unknownErrorSchema, error.response.data)

    if (unknownValidation.success) {
      return {
        isWellFormed: false,
        data: unknownValidation.data,
        message: unknownValidation.data.error ? unknownValidation.data.error : error.message,
        detail: '',
        status: error.response.status,
      }
    }
  }

  // The api might even return strings or god knows what else
  return {
    isWellFormed: false,
    data: error.response?.data,
    message: error.message,
    detail: '',
    status: error.response?.status ?? error.status ?? null,
  }
}

export const createError = (error: AxiosError): ApiError => {
  const parsedError = parseError(error)

  if (parsedError.isWellFormed) {
    return new ApiErrorWellFormed(parsedError, error)
  }
  else {
    return new ApiError(parsedError, error)
  }
}
