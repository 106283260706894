import { AxiosError } from 'axios'
import { arrayify, objEntries, objFromEntries } from '@lasso/shared/utils'
import { BaseSchema } from 'yup'

import { ApiErrorBase, FormErrorMapper } from './types'
import { mapFormError } from './utils'
import { unknownErrorSchema } from './validation'

/**
 * Converts AxiosError into something that's easier to handle.
 */
export class ApiError extends Error implements ApiErrorBase {
  override cause: AxiosError
  data
  override message
  detail
  status

  constructor(error: ApiErrorBase, cause: AxiosError) {
    super()

    this.cause = cause
    this.data = error.data
    this.message = error.message
    this.detail = error.detail
    this.status = error.status
  }

  getFormErrors = (mapper: FormErrorMapper = {}): Record<string, string> => {
    if (!this.isDataMatching(unknownErrorSchema)) {
      return {}
    }

    return objFromEntries(
      objEntries(this.data as Record<string, unknown>)
        .filter((entry): entry is [string, string | string[]] => {
          const errors = entry[1]

          return typeof errors === 'string'
            || (Array.isArray(errors) && errors.every(error => typeof error === 'string'))
        })
        .map(([key, errors]) => {
          return [mapFormError(mapper, key), arrayify(errors).join(', ')]
        }),
    )
  }

  isDataMatching = <T extends BaseSchema>(schema: T): this is ApiError & { data: T['__outputType'] } => {
    return schema.isValidSync(this.data)
  }
}
