import { AxiosError } from 'axios'
import { objEntries, objFromEntries, objValues } from '@lasso/shared/utils'

import { ApiError } from './ApiError'
import { ApiErrorParsed, ApiErrorWellFormedData, FormErrorMapper } from './types'
import { mapFormError } from './utils'

/**
 * Same as ApiError but the data conforms to ProblemDetails interface from ASP.NET Core
 * https://learn.microsoft.com/en-us/dotnet/api/microsoft.aspnetcore.mvc.problemdetails?view=aspnetcore-7.0
 */
export class ApiErrorWellFormed extends ApiError {
  declare data: ApiErrorWellFormedData

  constructor(error: ApiErrorParsed & { isWellFormed: true }, cause: AxiosError) {
    super(error, cause)
    this.detail = this.detail || this.getErrorsAsString()
  }

  override getFormErrors = (mapper: FormErrorMapper = {}): Record<string, string> => {
    return objFromEntries(
      objEntries(this.data.errors).map(([key, errors]) => [mapFormError(mapper, key), errors.join(', ')]),
    )
  }

  getErrorsAsString = () => {
    return objValues(this.data.errors).join(', ')
  }
}
