import { mixed, number, object, string } from 'yup'

// Format returned by the new API
export const wellFormedErrorSchema = object({
  errors: mixed<Record<string, string[]>>().test((value) => {
    if (!value) {
      return true
    }

    if (typeof value !== 'object') {
      return false
    }

    return Object.values(value).every(errors => Array.isArray(errors) && errors.every(error => typeof error === 'string'))
  }).default({}),
  title: string().required(),
  type: string().default(''),
  detail: string().default(''),
  status: number().required(),
  extensions: object().default({}),
})

// The old api returns whatever
export const unknownErrorSchema = object().required()
